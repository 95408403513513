<template>
  <section class="fixed top-0 right-0 z-[1000] m-4">
    <div
      class="flex flex-col items-center gap-4 overflow-hidden rounded-xl bg-white bg-opacity-70 py-3 pr-3 shadow-lg backdrop-blur-sm backdrop-filter transition-all duration-300"
      :class="{
        'h-[340px] w-[360px] pb-3': expanded && activeLayer.id === 'solutions',
        'h-[90px] w-auto': expanded && activeLayer.id !== 'solutions',
        'h-[40px] min-w-[210px] w-full max-w-max ': !expanded,
      }"
    >
      <header
        class="flex w-full items-center justify-between gap-2 text-black transition-all duration-300 pl-3"
      >
        <div
          class="flex items-center gap-1.5 font-bold capitalize"
          :class="expanded ? 'text-black' : 'text-[#0f0698]'"
        >
          <Square3Stack3DIcon class="h-5 w-5" />
          <p class="text-xs" v-html="menuLabel" />
        </div>
        <div
          class="h-5 w-5 cursor-pointer opacity-50 transition-all duration-300 hover:opacity-100"
        >
          <ChevronUpIcon @click="closeMenu" v-if="expanded" />
          <ChevronDownIcon @click="openMenu" v-else />
        </div>
      </header>
      <section v-if="expanded" class="w-full pl-3">
        <ul
          class="m-0 flex list-none items-center gap-2 p-0 text-xs transition-all duration-300"
        >
          <li
            v-html="layer.translation"
            v-for="layer in TranslatedLayers"
            :key="layer.id"
            @click="setLayerAndRedirect(layer)"
            class="cursor-pointer overflow-hidden rounded-md bg-[#0f0698] py-1 px-2 transition-all duration-300 capitalize"
            :class="
              isActiveLayer(layer.id)
                ? 'bg-opacity-100 text-white'
                : 'bg-opacity-5 hover:bg-opacity-20'
            "
          />
        </ul>
      </section>
      <section
        v-if="expanded && activeLayer.id === 'solutions'"
        class="flex flex-col gap-2 overflow-y-auto text-xs text-[#0f0698] transition-all duration-300"
        :class="expanded ? 'h-full w-full' : 'h-0 w-0'"
      >
        <div
          v-if="activeLayer.id === 'solutions'"
          class="flex justify-start gap-12"
        >
          <div class="flex flex-col gap-4">
            <div class="justify-left flex items-center gap-1 text-black pl-3">
              <p class="text-xs font-bold"
              v-html="getTranslations('solution portfolio')"
              ></p>
            </div>
            <ul
              class="m-0 flex list-none flex-col items-start justify-center gap-2 overflow-hidden p-0"
            >
              <li
                v-for="portfolio in portfolios"
                :key="portfolio.id"
                class="flex items-center justify-center gap-2 pl-3"
                :class="{ 
                  'bg-[#ff8a00b5] py-1 pr-2 ml-2 rounded': portfolio.id === 7,
                  'bg-[#005eff57] py-1 pr-2 ml-2 rounded': portfolio.id === 8
                 }"
              >
                <input
                  type="radio"
                  :id="portfolio.id"
                  :name="portfolio.name"
                  :value="portfolio"
                  v-model="selectedPortfolio"
                  @change="
                    setActivePortfolio({ activePortfolio: selectedPortfolio })
                  "
                  class="accent-[#0f0698] focus:ring-0"
                  :class="{ 
                    '-ml-2': portfolio.id === 7 || portfolio.id === 8,
                   }"
                />
                <label :for="portfolio.id" v-html="portfolio.name"></label>
              </li>
            </ul>
          </div>
          <div class="flex flex-col gap-4">
            <div class="justify-left flex items-center gap-1 text-black">
              <p  v-html="getTranslations('value drivers')"
                class="text-xs font-bold"></p>
            </div>
            <ul
              class="m-0 flex list-none flex-col items-start justify-center gap-2 overflow-hidden p-0"
            >
              <li
                v-for="valueDriver in valueDrivers"
                :key="valueDriver.id"
                class="flex items-center justify-center gap-2"
              >
                <input
                  type="checkbox"
                  :id="valueDriver.id"
                  :value="valueDriver"
                  v-model="selectedValueDrivers"
                  @change="updateValueDrivers()"
                  class="accent-[#0f0698] focus:ring-0"
                />
                <label :for="valueDriver.id" v-html="valueDriver.name"></label>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
<script lang="js" src="./FiltersMenu"></script>
