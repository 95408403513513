import {
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "InfoCard",
  components: {
    XMarkIcon,
  },
  props: {
    title: String,
    content: String,
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleInfo() {
      this.expanded = !this.expanded;
    },
  },
};
