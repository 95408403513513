<template>
  <div class="home flex h-screen flex-col">
    <header class="flex h-[20%] flex-col items-center justify-center">
      <img src="/images/icons/diversey-rooms-icon.svg" class="mx-auto mt-5 w-14 cursor-pointer hover:opacity-50"
        alt="Diversey GPS icon" />
      <h1 class="my-5 text-3xl">Diversey GPS</h1>
    </header>
    <main v-if="apiStatus === 'idle'" class="h-[80%] w-screen p-5 overflow-auto md:overflow-hidden">
      <LanguageButton class="fixed top-0 right-0 sm:top-0 sm:right-18" :toggleLanguageModal="toggleLanguageModal"/>
    <LanguageModal  v-if="show_language_modal" :toggleLanguageModal="toggleLanguageModal" class="fixed h-screen w-screen -top-4 -left-4" />
      <h2 class="h-[10%] text-center text-2xl text-black">
        {{ getTranslations('select a process') }}
      </h2>
      <!-- Desktop view -->
      <ul class="hidden h-[90%] justify-evenly gap-2 lg:flex  m-0 p-0 list-none">
        <li v-for="category in categories" :key="category.id"
          class="category-cards-container h-[90%] w-1/5 cursor-pointer transition-all duration-500 hover:w-1/4">
          <CategoryCard v-bind="category" :isScreen="false" />
        </li>
      </ul>
      <!-- Mobile View -->
        <ul class="grid grid-cols-2 items-stretch justify-center gap-4 lg:hidden  m-0 p-0 list-none overflox-y-scroll">
        <li v-for="category in categories" :key="category.id" class="min-h-[150px] w-full cursor-pointer">
          <router-link :to="'/en/' + category.slug">
            <CategoryCard v-bind="category" :isScreen="false" />
          </router-link>
        </li>
      </ul>


    </main>
    <section v-if="apiStatus === 'busy'" class="flex flex-grow items-center justify-center">
      <LoaderDots :isLoading="apiStatus === 'busy'" />
    </section>
    <section v-if="apiStatus === 'error'">
      <h1 class="text-center">{{ errorMessage }}</h1>
    </section>
    <section v-if="apiStatus === 'failed'">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">500</h1>
          <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl capitalize">
            {{ getTranslations('internal server error') }}
          </p>
          <p class="mb-4 text-lg font-light normal-case">
              {{ getTranslations('we apologize for the inconvenience. our team is currently addressing the issue. please check back later. thank you for your patience and understanding') }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="js" src="./HomeScreen.js"></script>
<style lang="scss" src="./HomeScreen.scss" scoped></style>
