<template>
  <div class="group relative h-full">
    <div
      class="category-card flex h-full flex-col items-center justify-center border border-solid border-[#B7B4B4] bg-cover bg-center p-7 text-center shadow-lg transition-all duration-300"
      v-bind:style="{ backgroundImage: 'url(' + image + ')' }"
      :class="
        isScreen
          ? 'full-screen'
          : 'not-full-screen rounded-xl before:rounded-xl xl:rounded-none xl:before:rounded-none '
      "
    >
      <div
        class="z-10 mt-auto w-9/10"
        :class="{
          'hidden opacity-0 transition-opacity group_hover:duration-300 ease-in group-hover:opacity-100 lg:block group-hover:delay-300 ':
            !isScreen,
          'xl:grid xl:w-full xl:grid-cols-2 xl:items-stretch xl:justify-center xl:gap-x-2':
            !isScreen && sectors.length > 4,
        }"
      >
        <ArrowButton
          v-for="sector in this.sectors"
          :key="sector.id"
          :title="sector.title"
          :click_handler="() => selectSector(sector)"
        />
      </div>
		
      <h2 class="z-10 mt-auto mb-5 text-2xl text-white">{{ title }}</h2>
    </div>
  </div>
</template>

<script lang="js" src="./CategoryCard.js"></script>
<style lang="scss" src="./CategoryCard.scss" scoped></style>
